import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['unit', 'summary', 'button', 'bundles']
  static values = { summaryOffClass: String, summaryExpression: String }

  selectSubItem(e) {
    const checkbox = e.target
    if (!checkbox.checked) {
      const bundleList = checkbox.closest('.bundle-items')
      let deselected = true
      bundleList.querySelectorAll('[data-multiselect-bundle-target=unit]').forEach((subItem) => {
        const itembox = this.checkbox(subItem)
        if (itembox.checked) deselected = false
      })
      if (deselected) {
        const parentCheckbox = this.checkbox(bundleList.closest('[data-multiselect-bundle-target=unit]'))
        parentCheckbox.checked = false;
        parentCheckbox.dispatchEvent(new Event('change'))
        bundleList.classList.toggle('hidden', true)
      }
    }
    this.updateBundlesDisplay(checkbox)
  }

  updateBundlesDisplay(bundleItem) {
    this.bundlesTargets.forEach(item => {
      if (bundleItem.getAttribute('data-bundle-id') == item.getAttribute('data-bundle-id')) {
        item.classList.toggle('hidden', !bundleItem.checked)
      }
    })
  }

  updateDisplay(currentUnit, isInit) {
    let sum = 0
    this.unitTargets.forEach(unit => {
      const isBundle = unit.getAttribute('data-is-bundle') == 'true'
      const checkbox = this.checkbox(unit)
      const quantity = unit.querySelector('[data-quantity]')
      this.setQuantityOnCheck(quantity, checkbox.checked)
      if (isBundle) {
        if (isInit) {
          unit.querySelectorAll('.bundle-items [type=checkbox]').forEach(item => {
            item.checked = false
            item.dispatchEvent(new Event('change'))
          })
          return
        }
        if (currentUnit && unit.getAttribute('data-item') == currentUnit.getAttribute('data-item')) {
          unit.querySelector('.bundle-items').classList.toggle('hidden', !checkbox.checked)
          unit.querySelectorAll('.bundle-items [type=checkbox]').forEach(item => {
            if (checkbox.checked) {
              if (!item.checked) {
                item.checked = true
                item.dispatchEvent(new Event('change'))
              }
            } else {
              if (item.checked) {
                item.checked = false
                item.dispatchEvent(new Event('change'))
              }
            }
            this.updateBundlesDisplay(item)
          })
        }
      } else {
        const hidden_fields = unit.querySelectorAll('[type=hidden]')
        hidden_fields.forEach(hidden_field => {
          hidden_field.disabled = !checkbox.checked
        })
        if (checkbox.checked) {
          sum += +quantity.value
        }
      }
      const editNumber = unit.querySelector('.edit-number')
      if (editNumber) {
        const maxValue = unit.querySelector('[data-number-max-value]').getAttribute('data-number-max-value')
        if (editNumber && quantity) {
          editNumber.classList.toggle('hidden', !checkbox.checked || +maxValue <= 1)
        }
      }
      unit.classList.toggle('selected', checkbox.checked && !isBundle)
    })
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = sum < 1
    }
    if (this.hasSummaryTarget) {
      this.summaryTargets.forEach(item => {
        item.classList.toggle(this.summaryOffClassValue, sum < 1)
        if (sum > 1) {
          item.innerHTML = this.summaryExpressionValue.replace("%n", sum).replace("%i", 'items')
        } else if (sum === 1) {
          item.innerHTML = this.summaryExpressionValue.replace("%n", sum).replace("%i", 'item')
        }
      })
    }
  }

  setQuantityOnCheck(quantity, checked) {
    if (quantity) {
      if (checked) {
        this.setQuantity(quantity, quantity.dataset.quantity)
      } else {
        this.setQuantity(quantity, 0)
      }
    }
  }

  setQuantity(quantity, value) {
    if (Number(quantity.value) !== Number(value)) {
      quantity.value = value
      quantity.dispatchEvent(new CustomEvent('change'))
    }
  }

  checkbox(unit) {
    return unit.querySelector('[type=checkbox]') || unit.querySelector('[type=radio]')
  }

  minusButton(unit) {
    return unit.querySelector('button.minus')
  }
  plusButton(unit) {
    return unit.querySelector('button.plus')
  }

  deselect(event) {
    if (event.preventDefault) {
      event.preventDefault()
    }
    console.log('event.detail', event.detail)
    let item_id = event.target?.dataset?.multiselectBundleIdParam
    if (!item_id) {
      item_id = event.detail?.multiselectBundleIdParam
    }
    console.log('deselect', { item_id })

    if (typeof item_id == 'string') {
      let hasSelection = false
      for (const unit of this.unitTargets) {
        const checkbox = this.checkbox(unit)
        const isBundleItem = unit.getAttribute('data-is-bundle-item') == "true"
        if (isBundleItem) continue
        if (unit.dataset.item == item_id) {
          setTimeout(() => {
            checkbox.checked = false
            checkbox.dispatchEvent(new Event('change'))
          }, 500)
        } else {
          hasSelection ||= checkbox.checked
        }
      }
      if (!hasSelection) {
        const onLastEvent = event.detail?.multiselectBundleOnLastEventParam
        if (onLastEvent) {
          console.log(`on last event, sending: ${onLastEvent}@window`)
          window.dispatchEvent(new CustomEvent(onLastEvent))
        }
      }
    }
  }

  numberListeners(unit) {
    this.checkbox(unit)?.addEventListener('change', () => {
      this.updateDisplay(unit)
    })
    this.minusButton(unit)?.addEventListener('click', () => {
      // setTimeout to wait for number change
      setTimeout(() => {
        this.updateDisplay()
      })
    })
    this.plusButton(unit)?.addEventListener('click', () => {
      setTimeout(() => {
        this.updateDisplay()
      })
    })
  }

  connect() {
    this.unitTargets.forEach(unit => {
      const checkbox = this.checkbox(unit)
      const quantity = unit.querySelector('[data-quantity]')
      quantity?.addEventListener('changeTo', (e) => {
        if (e && e.detail) {
          if (checkbox.checked) {
            this.setQuantity(quantity, e.detail.to)
            quantity.dataset.quantity = e.detail.to
          }
        }
      })
      this.numberListeners(unit)
    })
    this.updateDisplay(null, true)
  }
}