import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item', 'radio', 'submit', 'destination']

  select(e) {
    const value = e.target.value
    this.itemTargets.forEach(item => {
      item.classList.toggle('selected', item.dataset.id == value)
    })
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = false
    }

    this.disableDestinationTargets(e)
  }

  disableDestinationTargets(e) {
    if (!this.hasDestinationTarget) return

    let enabledElementClass = e.target.dataset.toggledElementClass;

    this.destinationTargets.forEach(element => {
      if (element.className.indexOf(enabledElementClass) == -1) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
      let disabled = !element.checkVisibility()
      if (element.nodeName == 'FIELDSET') {
        element.disabled = disabled
        if (element.hasAttribute('required')) {
          element.required = !disabled
        }
      } else {
        let input = element.querySelectorAll('select, input, textarea');
        input.forEach((e) => {
          e.disabled = disabled
          if (e.hasAttribute('required')) {
            e.required = !disabled
          }
        })
      }
    })
  }
}