import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['required', 'submit']

  connect() {
    const updateStatus = () => {
      this.submitTarget.disabled = this.requiredTargets.some(field => {
        if (field.tagName == 'TEXTAREA') {
          return field.value == ''
        }
        return !field.value
      })
    }
    for (const field of this.requiredTargets) {
      field.addEventListener('input', updateStatus)
    }
    updateStatus()
  }

  goBack(event) {
    event.preventDefault()
    history.go(-1)
  }

}
