const headers = {
  "Content-Type": "application/json"
}

const fetchApi = (method, url, params = {}) => fetch(url, {
  method,
  body: JSON.stringify(params),
  headers
})

const get = (url, params = {}) => fetchApi("GET", url, params)

const post = (url, params = {}) => fetchApi("POST", url, params)

const remove = (url, params = {}) => fetchApi("DELETE", url, params)

export default { get, post, remove }