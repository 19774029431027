import { Controller } from "@hotwired/stimulus"
import { find } from 'lodash'

export default class extends Controller {

  static targets = ['country', 'stateSelect', 'stateInput']

  toggleElement(element, isHide) {
    element.disabled = isHide
    const parentElement = element.closest('.state-wrap')
    parentElement.style.display = isHide ? 'none' : 'block'
    if (isHide) {
      element.removeAttribute('required')
    } else {
      element.setAttribute('required', 'required')
    }
  }

  updateState(list = [], stateValue = '') {
    const stateSelect = this.stateSelectTarget,
      stateInput = this.stateInputTarget
    if (list.length) {
      list.map((state) => {
        var option = new Option(state.name, state.code, false, false);
        stateSelect.appendChild(option)
      })
      stateSelect.value = stateValue && find(list, { code: stateValue }) ? stateValue : list[0].code
      stateSelect.dispatchEvent(new Event('change'));
    } else {
      stateInput.value = stateValue
      stateInput.dispatchEvent(new Event('change'));
    }
    this.toggleElement(stateInput, list.length)
    this.toggleElement(stateSelect, !list.length)
  }

  connect() {
    this.changed()
  }

  changed() {
    const countryCode = this.countryTarget.value,
      stateSelect = this.stateSelectTarget,
      stateInput = this.stateInputTarget
    const stateValue = stateInput.value
    stateSelect.value = ''
    stateInput.value = ''
    while (stateSelect.firstChild) {
      stateSelect.removeChild(stateSelect.firstChild);
    }
    stateSelect.dispatchEvent(new Event('change'));
    fetch('/countries/' + countryCode)
      .then(response => response.json())
      .then(data => {
        this.updateState(data.states || [], stateValue)
      })

  }
}
