import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['unit', 'summary', 'button', 'allButton', 'selectAll']
  static values = { summaryOffClass: String, summaryExpression: String, checkOnly: Boolean }

  autoSelectAll

  disableItem(unit, checked) {
    const hidden_fields = unit.querySelectorAll('[type=hidden]')
    hidden_fields.forEach(hidden_field => {
      hidden_field.disabled = !checked
    })
  }

  checkIsSelectAll() {
    let isSelectAll = true
    this.unitTargets.forEach(unit => {
      const checkbox = this.checkbox(unit)
      const quantity = unit.querySelector('[data-quantity]')
      const maxValue = unit.querySelector('[data-number-max-value]')
      if (!checkbox.checked) {
        isSelectAll = false
      }
      if (quantity && maxValue && quantity.value != maxValue.getAttribute('data-number-max-value')) {
        isSelectAll = false
      }
    })
    const selectAllBox = this.selectAllTarget.querySelector('[type=checkbox]')
    const allSelected = selectAllBox.checked
    if (isSelectAll && !allSelected) {
      this.autoSelectAll = true
      selectAllBox.checked = true
      selectAllBox.dispatchEvent(new Event('change'))
    } else if (!isSelectAll && allSelected) {
      this.autoSelectAll = true
      selectAllBox.checked = false
      selectAllBox.dispatchEvent(new Event('change'))
    }
  }

  updateDisplay() {
    let sum = 0
    let checked = false
    this.unitTargets.forEach(unit => {
      const checkbox = this.checkbox(unit)
      this.disableItem(unit, checkbox.checked)
      const quantity = unit.querySelector('[data-quantity]')
      this.setQuantityOnCheck(quantity, checkbox.checked)
      if (checkbox.checked) {
        if (!this.checkOnlyValue) {
          sum += +quantity.value
        } else {
          checked = true
        }
      }
      const editNumber = unit.querySelector('.edit-number')
      if (editNumber) {
        const maxValue = unit.querySelector('[data-number-max-value]').getAttribute('data-number-max-value')
        if (editNumber && quantity) {
          editNumber.classList.toggle('hidden', !checkbox.checked || +maxValue <= 1)
        }
      }
      unit.classList.toggle('selected', checkbox.checked)
    })
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = this.checkOnlyValue ? !checked : sum < 1
    }
    if (this.hasSummaryTarget) {
      this.summaryTargets.forEach(item => {
        item.classList.toggle(this.summaryOffClassValue, sum < 1)
        if (sum > 1) {
          item.innerHTML = this.summaryExpressionValue.replace("%n", sum).replace("%i", 'items')
        } else if (sum === 1) {
          item.innerHTML = this.summaryExpressionValue.replace("%n", sum).replace("%i", 'item')
        }
      })
    }
    if (this.hasSelectAllTarget) {
      this.checkIsSelectAll()
    }
  }

  setQuantityOnCheck(quantity, checked) {
    if (quantity) {
      if (checked) {
        this.setQuantity(quantity, quantity.dataset.quantity)
      } else {
        this.setQuantity(quantity, 0)
      }
    }
  }

  setQuantity(quantity, value) {
    if (Number(quantity.value) !== Number(value)) {
      quantity.value = value
      quantity.dispatchEvent(new Event('change'))
    }
  }

  checkbox(unit) {
    return unit.querySelector('[type=checkbox]') || unit.querySelector('[type=radio]')
  }

  minusButton(unit) {
    return unit.querySelector('button.minus')
  }
  plusButton(unit) {
    return unit.querySelector('button.plus')
  }

  deselect(event) {
    if (event.preventDefault) {
      event.preventDefault()
    }
    console.log('event.detail', event.detail)
    let item_id = event.target?.dataset?.multiselectIdParam
    if (!item_id) {
      item_id = event.detail?.multiselectIdParam
    }
    console.log('deselect', { item_id })

    if (typeof item_id == 'string') {
      let hasSelection = false
      for (const unit of this.unitTargets) {
        const checkbox = this.checkbox(unit)
        if (unit.dataset.item == item_id) {
          setTimeout(() => {
            checkbox.checked = false
            checkbox.dispatchEvent(new CustomEvent('change'))
          }, 500)
        } else {
          hasSelection ||= checkbox.checked
        }
      }
      if (!hasSelection) {
        const onLastEvent = event.detail?.multiselectOnLastEventParam
        if (onLastEvent) {
          console.log(`on last event, sending: ${onLastEvent}@window`)
          window.dispatchEvent(new CustomEvent(onLastEvent))
        }
      }
    }
  }

  numberListeners(unit) {
    this.checkbox(unit)?.addEventListener('change', () => {
      this.updateDisplay()
    })
    this.minusButton(unit)?.addEventListener('click', () => {
      // setTimeout to wait for number change
      setTimeout(() => {
        this.updateDisplay()
      })
    })
    this.plusButton(unit)?.addEventListener('click', () => {
      setTimeout(() => {
        this.updateDisplay()
      })
    })
  }

  updateQuantitySelector(unit) {
    const selector = unit.querySelector('.number')
    if (selector) {
      const maxValue = selector.getAttribute('data-number-max-value')
      selector.querySelector('.value').innerHTML = maxValue
      const quantity = selector.querySelector('[data-quantity]')
      this.setQuantity(quantity, maxValue)
      quantity.dataset.quantity = maxValue
      const plusBtn = unit.querySelector('.plus')
      plusBtn.classList.toggle('opacity-10', true)
    }
  }

  selectAll(e) {
    const checked = e.target.checked
    this.selectAllTarget.classList.toggle('selected', checked)
    this.unitTargets.forEach(unit => {
      const checkbox = this.checkbox(unit)
      if (!this.autoSelectAll) {
        checkbox.checked = checked
        checkbox.dispatchEvent(new Event('change'))
        this.updateQuantitySelector(unit)
        unit.classList.toggle('selected', checked)
      }
      checkbox.classList.toggle('opacity-40', checked)
      checkbox.disabled = checked
      if (checked) {
        setTimeout(() => {
          this.disableItem(unit, false)
        })
      }
    })
    if (this.hasAllButtonTarget) {
      this.allButtonTarget.classList.toggle('hidden', !checked)
    }
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.toggle('hidden', checked)
    }
    this.autoSelectAll = false
  }

  connect() {
    this.unitTargets.forEach(unit => {
      const checkbox = this.checkbox(unit)
      if (!this.checkOnlyValue) {
        const quantity = unit.querySelector('[data-quantity]')
        quantity?.addEventListener('changeTo', (e) => {
          if (e && e.detail) {
            if (checkbox.checked) {
              this.setQuantity(quantity, e.detail.to)
              quantity.dataset.quantity = e.detail.to
            }
          }
        })
      }
      this.numberListeners(unit)
    })
    this.updateDisplay()
  }
}
