import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['step']
  static values = {
    defaultIndex: String
  }

  index = 0

  connect() {
    this.index = +(this.defaultIndexValue || 0)
    this.stepTargets.forEach((item, index) => {
      item.classList.toggle('hidden', index != this.index)
    })
  }
  next() {
    this._go(1)
  }
  prev() {
    this._go(-1)
  }
  back() {
    this._go(-this.stepTargets.length)
  }
  _go(step) {
    if (step > 0 && this.index + step >= this.stepTargets.length) {
      return
    } else if (step < 0 && this.index + step < 0) {
      history.go(-1)
      return
    } else {
      this.stepTargets[this.index].classList.add('hidden')
      this.index += step
      this.stepTargets[this.index].classList.remove('hidden')
    }
  }
}