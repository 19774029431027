import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trigger', 'popup']
  current

  connect() {
    if (this.hasTriggerTarget) {
      this.triggerTarget.classList.add('cursor-pointer', 'z-100')
      this.popupTarget.classList.add('pointer-events-none', 'hidden', 'absolute')
      if ('ontouchstart' in window) {
        this.triggerTarget.addEventListener('click', this.present.bind(this))
        document.addEventListener('click', this.dismiss.bind(this))
      } else {
        this.triggerTarget.addEventListener('mouseenter', this.present.bind(this))
        this.triggerTarget.addEventListener('mouseleave', this.dismiss.bind(this))
      }
    }
  }

  dismiss() {
    this.popupTarget.classList.add('hidden')
    this.popupTarget.classList.remove('flex')
  }

  present(event) {
    event.preventDefault()
    event.stopPropagation()
    this.popupTarget.classList.remove('hidden')
    this.popupTarget.classList.add('flex')
  }
}