import { Controller } from "@hotwired/stimulus"
import ace from 'ace-builds/src-min-noconflict/ace'
import 'ace-builds/src-min-noconflict/mode-json'
import 'ace-builds/src-min-noconflict/ext-beautify'

export default class extends Controller {
  connect() {
    ace.config.setModuleUrl("ace/mode/json_worker", "/js/worker-json.js");
    this.instantiateEditor(this.element);
  }

  instantiateEditor(editorDiv) {
    let editor = ace.edit(editorDiv);
    let beautify = ace.require('ace/ext/beautify');
    editor.session.setMode("ace/mode/json");
    editor.setReadOnly(true);
    beautify.beautify(editor.session);
  }
}
