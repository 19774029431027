import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['search', 'address1', 'address2', 'city', 'state', 'country', 'zip', 'form', 'submit'];

  async connect() {
    await deferred_map.promise;
    let stimulus = this;

    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    };

    // disable enter key from submitting form
    this.searchTarget.addEventListener('keydown', function(e) {
      if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
        if (e.target.nodeName == 'INPUT' && e.target.type == 'text') {
          e.preventDefault();
          return false;
        }
      }
    }, true);

    this.autocomplete = new google.maps.places.Autocomplete(this.searchTarget, options);
    this.autocomplete.addListener("place_changed", () => { stimulus.fillAddress() });
  }

  fillAddress() {
    const place = this.autocomplete.getPlace();

    this.clearAddress();

    let results = this.formattedComponents(place.address_components);

    this.address1Target.value = results.street_number + ' ' + results.route;
    this.cityTarget.value = results.locality;
    this.stateTarget.value = results.administrative_area_level_1;
    this.countryTarget.value = results.country;
    this.countryTarget.dispatchEvent(new Event('change'))
    this.zipTarget.value = results.postal_code;

  }

  formattedComponents(components) {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name'
    }
    let results = {};

    for (let i = 0; i < components.length; i++) {
      const addressType = components[i].types[0];
      if (componentForm[addressType]) {
        const val = components[i][componentForm[addressType]];
        results[addressType] = val;
      }
    }

    return results;
  }

  clearAddress() {
    this.address1Target.value = "";
    this.address2Target.value = "";
    this.cityTarget.value = "";
    this.stateTarget.value = "";
    this.countryTarget.value = "";
    this.zipTarget.value = "";
  }
}
