import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from '../helpers/utils'

export default class extends Controller {

  static targets = ["part", "button", "react", "checkbox"]
  static values = { index: Number, historyKey: String }

  historyKey() {
    return "partial_form_index" + (this.historyKeyValue ?? "")
  }

  pushState(scenario) {
    if (history.state[this.historyKey()] !== this.indexValue) {
      if (this.indexValue === 0) {
        this._replaceOrRestoreState(scenario === 'connect')
      } else {
        this._pushState()
      }
    }
  }

  _replaceOrRestoreState(restoreFirst) {
    if (restoreFirst) {
      // try restore first
      const id = history.state[this.historyKey()]
      if (id) {
        this.indexValue = id
        this.partTargets[this.indexValue].classList.toggle('invisible', false)
        return
      }
    }
    // @discussion https://ilabs-team.slack.com/archives/D02F14JB14N/p1637755228016600
    history.replaceState({ ...history.state, [this.historyKey()]: this.indexValue, i: (history.state.i || 0) + 1 }, '', `#${this.indexValue}`)
  }

  _pushState() {
    history.pushState({ ...history.state, [this.historyKey()]: this.indexValue, i: (history.state.i || 0) + 1 }, '', `#${this.indexValue}`)
  }

  reactFunctions = {
    'update-quantity': function(element) {
      element.querySelector('[data-summary-item-quantity]').innerHTML = this.value
    },
    'hide-other-claim-confirmation': function(element, param) {
      element.classList.toggle("invisible", element.dataset.partialFormClaimConfirmation !== param)
    },
    'replace-content': function(element, param) {
      element.innerHTML = param
    },
    'hide-unchecked': function(element) {
      element.classList.toggle("hidden", !this.checked)
    },
    'select-all': function(element) {
      element.innerHTML = this.checked ? 'Cancel order' : 'Cancel items'
    },
    'hide-other-exchange-pages': function(element, param) {
      element.classList.toggle("invisible", param !== "exchange")
    },
    'set-resolution': function(element, param) {
      element.dataset.resolutionValue = param
    },
    'provider-fee': function(element, param) {
      const fee = Number(element.getAttribute("data-fee") || 0) + Number(param || 0)
      const currency = element.getAttribute("data-currency")
      element.innerHTML = formatCurrency(fee, currency, "FREE")
    },
    'summary-fee': function(element, param) {
      const fee = document.querySelector(`.price[${param}]`).innerText
      element.innerHTML = fee
    },
    'toggle-resolution': function(element) {
      const siblings = Array.from(element.parentNode.children);
      siblings.forEach((sibling) => {
        sibling.classList.toggle('hidden', sibling !== element);
      });
    },
    'proof-require': function(element, param) {
      const requirement = JSON.parse(param)
      element.setAttribute('data-dropzone-requiring-upload', requirement.upload)
      element.setAttribute('data-dropzone-requiring-note', requirement.note)
    }
  }

  _reaction(reactTarget, suffix = '') {
    const selector = reactTarget.dataset['partialFormReactElements' + suffix]
    if (selector) {
      const elements = document.querySelectorAll(selector)
      const namedFn = reactTarget.dataset['partialFormReactFnName' + suffix]
      const param = reactTarget.dataset['partialFormReactFnParam' + suffix]
      for (const element of elements) {
        this.reactFunctions[namedFn].call(reactTarget, element, param)
      }
    }
  }

  reaction(reactTarget) {
    this._reaction(reactTarget)
    this._reaction(reactTarget, '-1')
    this._reaction(reactTarget, '-2')
    this._reaction(reactTarget, '-3')
  }

  /* This function is run when the associated HTML element has data in the `data-form-errors-value` attribute.
   * It will find the errors and loop through the form parts to find the correct element where it should display the error.
   * As it loops through the form parts, it renders the error in the first *visible* form part and breaks out of the loop.
   * Once done with the loop, it sets the `indexValue` to the index of the loop
   * and shows the form part with that `indexValue`
   */
  locateError() {
    if (!this.element.dataset.formErrorsValue) return // return if there are no errors

    const errors = JSON.parse(this.element.dataset.formErrorsValue)

    try {
      if (errors && Object.keys(errors).length > 0) {
        const key = Object.keys(errors)[0]
        let index = 0

        // Loop through the errors and find the first visible form part to display the error
        for (const target of this.partTargets) {
          const el = target.querySelector(`[data-form-error-for='${key}']`)

          // Only render in this element if the form part is visible and break out of the
          // loop. There are scenarios where this particular form parge may not be visible
          // but has the same form elements as another form part so we want to be sure to
          // show the error in the correct form part. We explicitly see this in
          // the theft claim form where a threshold dollar amount determines
          // which form part is visible even though they have the same form elements.
          if (el && !target.classList.contains('invisible')) {
            this.renderError(key, errors[key], el)
            break
          }

          index++
        }
        this.indexValue = index
      }
    } catch (error) {
      console.log(errors, error)
    }
  }

  renderError(key, error, el) {
    this.element.classList.add(`errors-${key}`)
    el.classList.add('errors')
    el.innerHTML = ''
    const i = document.createElement('i')
    i.classList.add('info-reverse')
    el.appendChild(i)
    const span = document.createElement('span')
    span.innerHTML = error
    el.appendChild(span)
  }

  syncFormItemIndex() {
    const all_checked = this.checkboxTargets.filter(checkbox => checkbox.className.includes("items-checkbox") && checkbox.checked && !checkbox.className.includes('bundle-item'))
    all_checked.forEach((checkbox, index) => {
      document.querySelectorAll(checkbox.dataset.partialFormCheckboxElements).forEach(el => {
        el.querySelectorAll(`[data-form-error-for-n]`).forEach(container => {
          container.dataset.formErrorFor = container.dataset.formErrorForN.replace('%', index)
        })
        el.dataset.partialFormItemIndex = index + 1
        el.dataset.partialFormItemTotal = all_checked.length
        el.dataset.partialFormIsLast = all_checked.length == index + 1
      })
    })
  }

  connect() {
    this.syncFormItemIndex()
    this.locateError()
    this.pushState('connect')
    this.updateDisplay()
    this.connectCheckboxesWithFormItems()
    this.registerReaction()
    window.addEventListener('popstate', (popstateEvent) => {
      if (popstateEvent?.state?.hasOwnProperty(this.historyKey())) {
        this.indexValue = popstateEvent.state[this.historyKey()]
        this.updateDisplay()
      } else {
        console.log(`history key "${this.historyKey()}" not found`)
      }
    })
  }

  connectCheckboxesWithFormItems() {
    if (this.hasCheckboxTarget) {
      const elements = (checkbox) => document.querySelectorAll(checkbox.dataset.partialFormCheckboxElements)
      for (const checkbox of this.checkboxTargets) {
        checkbox.addEventListener('change', () => {
          for (const element of elements(checkbox)) {
            element.classList.toggle('invisible', !checkbox.checked)
            for (const input of element.querySelectorAll('[name]')) {
              input.disabled = !checkbox.checked
            }
          }
          this.syncFormItemIndex()
        })
        checkbox.dispatchEvent(new CustomEvent('change')) // this reloads page after post
      }
    }
  }

  registerReaction() {
    this.reactTargets.forEach(reactTarget => {
      if (reactTarget.tagName == 'A') {
        reactTarget.addEventListener('click', () => {
          this.reaction(reactTarget)
          if (reactTarget.dataset.partialFormReactThenNext == 'true') {
            this.next()
          }
        })
      } else {
        reactTarget.addEventListener('change', () => {
          this.reaction(reactTarget)
        })
      }
    })
  }

  updateDisplay() {
    this.partTargets.forEach((part, index) => {
      if (index === this.indexValue) {
        part.classList.add('flex')
        let title = part.dataset.tabsTitleParam
        if (!title && part.dataset.tabsTitleFn) {
          title = part.dataset.tabsTitleFn
            .replace('${index}', part.dataset.partialFormItemIndex)
            .replace('${total}', part.dataset.partialFormItemTotal)
        }
        const event = new CustomEvent("update-tabs", {
          detail: {
            title,
            tab: parseInt(part.dataset.tabsTabParam),
            progress: part.dataset.tabsProgressParam
          }
        })
        window.dispatchEvent(event)
        this._display(part)
      } else {
        this._hide(part)
      }
    })
  }

  _display(part) {
    if (part.classList.contains('hidden')) {
      part.dispatchEvent(new CustomEvent('appear'))
      part.classList.remove('hidden')
    }
  }

  _hide(part) {
    part.classList.add('hidden')
    if (part.classList.contains('flex')) {
      part.dispatchEvent(new CustomEvent('disappear'))
      part.classList.remove('flex')
    }
  }

  shouldIgnoreCurrent() {
    return this.partTargets[this.indexValue].classList.contains('invisible')
  }

  previous(event) {
    event.preventDefault()
    this._go(() => true)
  }

  go(event) {
    event.preventDefault()
    const id = event.target?.dataset?.partialFormIdParam
    this._go(() => typeof id === 'string' && this.partTargets[this.indexValue].dataset.partialFormId == id)
  }

  _go(fn) {
    let page = 0
    while (this.indexValue > 0) {
      this.indexValue--
      if (!this.shouldIgnoreCurrent()) {
        page++
        if (fn()) {
          break
        }
      }
    }

    if (page > 0) {
      if (this.indexValue == 0) {
        this.pushState()
      } else {
        console.log(`history.go(${-page})`, history.state)
        history.go(-page)
      }
      this.updateDisplay()
    } else {
      history.go(-2)
    }
  }

  next(event) {
    event?.preventDefault()
    console.group('partial-form#next')
    while (true) {
      if (this.indexValue + 1 == this.partTargets.length) {
        const form = document.querySelector('form')
        form?.submit()
        break
      }
      this.indexValue++
      if (this.shouldIgnoreCurrent()) {
        continue
      } else {
        break
      }
    }
    this.pushState('next')
    this.updateDisplay()
    console.groupEnd('partial-form#next')
  }
}
