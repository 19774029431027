import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['cell']

  offsetX = 0
  initialX = []
  isDragging = false

  connect() {
    this.element.addEventListener('mousedown', this.mouseDownHandler)
  }

  stopIndex() {
    let minX = Number.MAX_SAFE_INTEGER
    let minIndex = 0
    let index = 0
    for (const x of this.initialX) {
      const diff = Math.abs(-this.offsetX - x)
      if (minX > diff) {
        minX = diff
        minIndex = index
      }
      index++
    }
    this.offsetX = -this.initialX[minIndex]
  }

  mouseDownHandler = (e) => {
    if (this.initialX.length !== this.cellTargets.length) {
      const x0 = this.cellTargets[0].getBoundingClientRect().x
      this.initialX = this.cellTargets.map(cell => cell.getBoundingClientRect().x - x0)
      console.log(this.initialX)
    }
    this.isDragging = true
    this.element.style.cursor = 'grabbing'

    this.element.addEventListener('mousemove', this.mouseMoveHandler)
    document.addEventListener('mouseup', this.mouseUpHandler)
  }

  mouseMoveHandler = (e) => {
    if (this.isDragging) {
      this.offsetX += e.movementX
      this.element.style.transform = "translateX(" + this.offsetX + "px)"
    }
  }

  mouseUpHandler = (e) => {
    this.isDragging = false
    this.element.style.cursor = 'grab'
    this.stopIndex()
    this.element.style.transform = "translateX(" + this.offsetX + "px)"
    this.element.removeEventListener('mousemove', this.mouseMoveHandler.bind)
    document.removeEventListener('mouseup', this.mouseUpHandler)
  }
}