import { Controller } from "@hotwired/stimulus"
import http from '~/helpers/http'

export default class extends Controller {
  static targets = ['message', 'thumb', 'feedback']

  like(event) {
    event.preventDefault();
    event.stopPropagation();
    const likeBtn = event.target
    const liked = this.clearLikeState('like')
    const sid = this.element.getAttribute('data-sid')
    const url = "messages/" + sid + "/feedback"
    if (liked) {
      http.remove(url)
    } else {
      http.post(url, { score: 1 })
      likeBtn.classList.add('active')
    }
  }
  dislike(event) {
    event.preventDefault();
    event.stopPropagation();
    const likeBtn = event.target
    const disliked = this.clearLikeState('dislike')
    const sid = this.element.getAttribute('data-sid')
    const url = "messages/" + sid + "/feedback"
    if (disliked) {
      http.remove(url)
    } else {
      http.post(url, { score: 0 })
      likeBtn.classList.add('active')
      this.showDislikeFeedbackBtn()
    }
  }

  showDislikeFeedbackBtn() {
    const item = document.createElement('div');
    item.className = 'message-feedback dislike'
    item.setAttribute('data-action', 'click->conversation-feedback#showDislikeFeedback')
    item.setAttribute('data-message-feedback-target', 'feedback')
    item.innerHTML = '<span>Add feedback</span>'
    this.messageTarget.appendChild(item)
  }

  clearLikeState(classname) {
    let isActive = false
    this.thumbTargets.forEach(icon => {
      if (icon.classList.contains('active')) {
        isActive = icon.classList.contains(classname)
        icon.classList.remove('active')
      }
    })
    this.feedbackTargets?.forEach(item => {
      item.remove()
    })
    return isActive
  }
}
