import { Controller } from "@hotwired/stimulus"
import { Picker } from 'emoji-mart'

window.EmojiMartPicker = Picker

export default class extends Controller {
  static targets = ['container', 'icon', 'input']

  connect() {
    const pickerOptions = {
      onEmojiSelect: (emoji) => this.onEmojiSelect(emoji),
      onClickOutside: (e) => this.closeEmoji(e),
      perLine: 8,
      maxFrequentRows: 2,
      navPosition: 'bottom',
      previewPosition: 'none',
      skinTonePosition: 'none'
    }
    const picker = new Picker(pickerOptions)
    this.containerTarget.appendChild(picker);
  }

  disconnect() {
    this.containerTarget.innerHTML = ''
  }

  onEmojiSelect(emoji) {
    this.inputTarget.value += emoji.native
    this.containerTarget.classList.add('hidden')
  }

  closeEmoji(e) {
    if (e && e.target.id != this.iconTarget.id) {
      this.containerTarget.classList.add('hidden')
    }
  }

  toggleEmoji() {
    this.containerTarget.classList.toggle('hidden')
  }
}