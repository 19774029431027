import { Controller } from "@hotwired/stimulus"
import http from '~/helpers/http'

export default class extends Controller {
  static targets = ['form', 'message', 'messages', 'actions', 'dislike', 'endChat', 'score', 'formContainer', 'startNewContainer', 'dislikeFeedback']
  static values = {
    conversationId: String
  }

  currentSid = ''
  score = 0

  // Dislike
  showDislikeFeedback(event) {
    event.preventDefault();
    event.stopPropagation();
    const messageItem = event.target.closest('.message-item')
    this.showFeedbackHeader(this.dislikeFeedbackTarget)
    messageItem.querySelector('.message-feedback')?.classList.add('hidden')
    this.dislikeTarget.classList.remove('hidden')
    const messageContent = messageItem.querySelector('.message-bubble').innerHTML
    this.dislikeFeedbackTarget.querySelector('.dislike-content').innerHTML = messageContent
    messageItem.querySelector('.message-bubble').classList.add('active')
    this.currentSid = messageItem.closest('.message-wrap').getAttribute('data-sid')
  }
  sendDislikeFeedback() {
    if (!this.messageTarget.value) return
    http.post("messages/" + this.currentSid + "/feedback", {
      score: 0,
      comments: this.messageTarget.value
    })
    this.closeFeedback()
    const item = document.createElement('div');
    item.className = 'message-feedback received'
    item.setAttribute('data-message-feedback-target', 'feedback')
    item.innerHTML = '<span>Feedback received</span>'
    this.messagesTarget.querySelector(`.message-wrap[data-sid="${this.currentSid}"] .message-item`).appendChild(item)
  }
  closeFeedback() {
    this.dislikeFeedbackTarget.classList.add('hidden')
    this.formTarget.classList.remove('no-top')
    this.messageTarget.placeholder = 'Type your message here...'
    this.dislikeTarget.classList.add('hidden')
    this.actionsTarget.classList.remove('hidden')
    const messageItem = this.messagesTarget.querySelector(`.message-wrap[data-sid="${this.currentSid}"]`)
    messageItem.querySelector('.message-bubble')?.classList.remove('active')
    messageItem.querySelector('.message-feedback')?.classList.remove('hidden')
    this.dispatch('reset')
  }

  // End Chat
  endChat(event) {
    event.preventDefault();
    event.stopPropagation();
    http.post(this.conversationIdValue + "/completion")
    this.showEndFeedback()
  }
  showEndFeedback(isReShow) {
    this.showFeedbackHeader(this.scoreTarget)
    this.endChatTarget.classList.remove('hidden')
    if (!isReShow) {
      this.dispatch('setEndTime', { detail: { date: Date.now() } })
    }
    this.initScore()
    this.dispatch('scrollToEnd')
  }
  reShowEndFeedback() {
    this.startNewContainerTarget.classList.add('hidden')
    this.formContainerTarget.classList.remove('hidden')
    this.showEndFeedback(true)
  }
  endChatFeedback() {
    if (!this.messageTarget.value) return
    http.post(this.conversationIdValue + "/feedback", {
      score: this.score,
      comments: this.messageTarget.value
    })
    this.showStartNew(true)
  }
  skipFeedback() {
    this.showStartNew(false)
  }

  showFeedbackHeader(header) {
    header.classList.remove('hidden')
    this.formTarget.classList.add('no-top')
    this.messageTarget.placeholder = 'Type your feedback here...'
    this.messageTarget.focus()
    this.actionsTarget.classList.add('hidden')
    this.dispatch('reset')
  }
  showStartNew(isRated) {
    this.formContainerTarget.classList.add('hidden')
    this.startNewContainerTarget.classList.remove('hidden')
    this.startNewContainerTarget.querySelector('.rated').classList.toggle('hidden', !isRated)
    this.startNewContainerTarget.querySelector('.no-rated').classList.toggle('hidden', isRated)
  }
  initScore() {
    this.scoreTarget.querySelectorAll('.score-list i').forEach((item, index) => {
      item.addEventListener('click', () => this.setScore(index))
    })
  }
  setScore(currentScore) {
    this.score = currentScore + 1
    this.scoreTarget.querySelectorAll('.score-list i').forEach((item, index) => {
      if (index <= currentScore) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }
}
