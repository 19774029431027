import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['before', 'after', 'responseURL']
  static values = { containerClass: String, containerClassBefore: String, containerClassAfter: String }

  updateContainer() {
    if (this.containerClassValue) {
      const container = this.element.querySelector('.' + this.containerClassValue)
      if (container) {
        if (this.containerClassBeforeValue) {
          container.classList.toggle(this.containerClassBeforeValue, false)
        }
        if (this.containerClassAfterValue) {
          container.classList.toggle(this.containerClassAfterValue, true)
        }
      }
    }
  }

  connect() {
    const form = this.element.querySelector('form')
    this.afterTargets.forEach(target => target.classList.add('hidden'))
    form.addEventListener('submit', event => {
      event.preventDefault()
      fetch(form.action, {
        method: form.method,
        body: new FormData(form)
      }).then((data) => {
        console.log(data)
        if (data.url && this.hasResponseURLTarget) {
          this.responseURLTarget.href = data.url
        }
        this.updateContainer()
        this.beforeTargets.forEach(target => target.classList.add('hidden'))
        this.afterTargets.forEach(target => target.classList.remove('hidden'))
      })
    })
  }
}