import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['trigger', 'content']

  trigger;
  arrow;
  svg;
  rect;
  width;

  arrow_path() {
    return `M${this.left} ${this.height - 4} c-1.5 2 -4.5 2 -6 0 l-12 -14 l30 0z`
  }

  connect() {
    this.trigger = this.element.querySelector('.info')
    if(!this.trigger) return
    this.trigger.addEventListener('click', this.toggle.bind(this))
    this.observer = new ResizeObserver(this.calcLeft.bind(this))
    this.observer.observe(this.element.parentElement)
    const style = getComputedStyle(this.contentTarget)
    this.width = Number(style.getPropertyValue('--q-width').replace('px', ''))
    this.height = Number(style.getPropertyValue('--q-height').replace('px', ''))
    this.left = 140
    this.contentTarget.innerHTML = 
    `<svg class="absolute" style="left: var(--q-x); top: var(--q-y);" xmlns="http://www.w3.org/2000/svg" width="${this.width}" height="${this.height}" fill="current">
      <g>
        <rect width="${this.width}" height="${this.height - 17}" rx="10"></rect>
        <path d="${this.arrow_path()}"></path>
      </g>
    </svg>` + this.contentTarget.innerHTML
    this.svg = this.contentTarget.querySelector('svg')
    this.arrow = this.contentTarget.querySelector('path')
    this.rect = this.contentTarget.querySelector('rect')
  }

  disconnect() {
    if(this.observer) {
      this.observer.disconnect()
    }
  }

  calcLeft() {
    if (!this.element.offsetParent) return
    const min_left = this.trigger.offsetLeft - 24 + 10;
    const min_right = this.width - (this.element.offsetParent.offsetWidth - this.element.offsetLeft - 24 + 10)
    let left = this.width / 2 + 3;
    if (min_left < left) {
      left = min_left
    }
    if (min_right > left) {
      left = min_right
    }
    this.setLeft(left)
  }

  setLeft(left) {
    this.left = left
    this.contentTarget.style.left = `${-left + 1.5}px`
    this.arrow.setAttribute('d', this.arrow_path())
  }

  setHeight(height) {
    this.height = height
    this.contentTarget.querySelector('p').style.height = `${height - 47}px`
    this.contentTarget.style.setProperty('--q-height', `${height}px`)
    this.rect.setAttribute('height', height - 17)
    this.svg.setAttribute('height', height)
    this.arrow.setAttribute('d', this.arrow_path())
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.contentTarget.classList.toggle('hidden')
    const scrollHeight = this.contentTarget.querySelector('p').scrollHeight
    if (!this.recalculated && scrollHeight > 0) {
      this.setHeight(Math.min(160, scrollHeight) + 47)
      this.recalculated = true
    }
    this.calcLeft()
  }
}