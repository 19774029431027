import { Controller } from "@hotwired/stimulus"
import Dropzone from 'dropzone'
import { DirectUpload } from "@rails/activestorage"

const Circle = { r: 11, w: 2, R: 0, d: 0, c: 0 }
Circle.R = Circle.r + Circle.w
Circle.d = 2 * Circle.R
Circle.c = Math.PI * 2 * Circle.r

const template = `
<div class="dz-preview dz-file-preview">
  <img class="dz-image" data-dz-thumbnail />
  <div class="dz-ext">pdf</div>
  <video controls></video>
  <div class="dz-image-cover"></div>
  <svg class="dz-progress" width="${Circle.d}" fill="transparent" height="${Circle.d}" viewPort="0 0 ${Circle.d} ${Circle.d}" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle class="dz-progress-bg" r="${Circle.r}" cx="${Circle.R}" cy="${Circle.R}" stroke="white" stroke-opacity="0.5" stroke-width="${Circle.w}" stroke-dasharray="${Circle.c}"></circle>
    <circle data-dz-uploadprogress class="dz-upload" r="${Circle.r}" cx="${Circle.R}" cy="${Circle.R}" stroke="white" stroke-width="${Circle.w}" stroke-dasharray="${Circle.c}" style="--c: ${Circle.c}px" stroke-linecap="round" transform="rotate(-90 ${Circle.R} ${Circle.R})"></circle>
  </svg>
  <div class="dz-details">
    <div class="dz-filename"><span data-dz-name notranslate></span></div>
  </div>
</div>`

const DropzoneOptions = {
  thumbnailWidth: 112,
  thumbnailHeight: 112,
  autoQueue: false,
  uploadMultiple: true,
  addRemoveLinks: true,
  dictRemoveFile: '',
  previewTemplate: template,
}
class Upload {
  onprogress;
  file;
  constructor(file, url, callback, onprogress) {
    this.onprogress = onprogress
    this.file = file
    const upload = new DirectUpload(file, url, this)
    console.log(`uploading ${file.name}`, url)
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error)
        alert('Something went wrong...')
      } else {
        callback(blob.signed_id)
        this.onprogress(100)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
    request.upload.addEventListener("loadend",
      event => this.onprogress(99))
  }

  directUploadDidProgress(event) {
    const percent = event.loaded * 100 / event.total
    console.log(`uploading ${this.file.name} ${percent}%`)
    this.onprogress(Math.min(99, percent))
  }
}

export default class extends Controller {
  static targets = ['fileField', 'dropzone', 'clickable', 'preview', 'submit', 'note']

  static values = { acceptedFiles: String }


  numberOfFilesInProgress = 0

  requiringUpload = false

  requiringNote = false

  onprogress(file, progress) {
    const progressBar = file.previewElement.querySelector("[data-dz-uploadprogress]")
    if (progressBar) {
      progressBar.style.strokeDashoffset = -progress / 100 * Circle.c
      if (progress === 100) {
        setTimeout(() => {
          file.previewElement.querySelector('.dz-progress').classList.add('opacity-0')
          file.previewElement.querySelector('.dz-image-cover')?.remove()
          this.numberOfFilesInProgress--
          this.updateSubmitButtonStatus()
        }, 500)
      }
    }
  }

  previewMedia(file) {
    const videoEl = file.previewElement.querySelector('video')
    if (file.type.startsWith('video/') && videoEl.canPlayType(file.type) !== 'no') {
      const fileURL = URL.createObjectURL(file)
      videoEl.setAttribute('src', fileURL)
      file.previewElement.classList.remove('dz-file-preview')
      file.previewElement.classList.add('dz-image-preview')
    } else if (file.type.startsWith('image/')) {
      videoEl.remove()
      file.previewElement.classList.remove('dz-file-preview')
      file.previewElement.classList.add('dz-image-preview')
    } else {
      videoEl.remove()
    }
  }

  updateSubmitButtonStatus() {
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = this.numberOfFilesInProgress > 0 ||
        this.requiringUpload && this.myDropzone.files.length === 0 ||
        this.requiringNote && this.hasNoteTarget && this.noteTarget.value == ""
    }
  }

  upload(file, input) {
    this.updateSubmitButtonStatus()
    const url = this.fileFieldTarget.dataset.directUploadUrl
    new Upload(file, url, (signed_id) => {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("value", signed_id)
      hiddenField.name = input.name
      hiddenField.dataset.errorKey = input.dataset.errorKey
      this.element.appendChild(hiddenField)
      console.log(hiddenField)
      file.hiddenField = hiddenField
    }, progress => {
      this.onprogress(file, progress)
    })
  }


  createDropzone() {
    return new Dropzone(this.dropzoneTarget, {
      url: this.fileFieldTarget.dataset.directUploadUrl,
      previewsContainer: this.previewTarget,
      clickable: this.clickableTargets,
      acceptedFiles: this.acceptedFilesValue,
      ...DropzoneOptions
    })
    .on('addedfile', (file) => {
      const ext = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (ext) {
        file.previewElement.querySelector('.dz-ext').innerHTML = ext
      }
      this.previewMedia(file)
      this.upload(file, this.fileFieldTarget)
    })
    .on('removedfile', (file) => {
      if (file.hiddenField) {
        file.hiddenField.remove()
      }
    })
  }

  connect() {
    this.fileFieldTarget.classList.add('hidden')
    this.myDropzone = this.createDropzone()

    const selector = this.element.dataset.dropzoneRequiringSelector
    const parentSelector = this.element.dataset.partialFormParentSelector

    if (this.hasNoteTarget) {
      this.noteTarget.addEventListener('input', () => {
        this.updateSubmitButtonStatus()
      })
    }
    if (selector !== undefined && parentSelector !== undefined) {
      const parent = document.querySelector(parentSelector)
      parent.addEventListener('appear', () => {
        this.requiringUpload = JSON.parse(this.element.dataset.dropzoneRequiringUpload)
        this.requiringNote = JSON.parse(this.element.dataset.dropzoneRequiringNote)
        console.log(`requireNote: ${this.requiringNote}, requireUpload: ${this.requiringUpload}`)
        this.clickableTarget.innerHTML = `Upload files${this.requiringUpload ? ' (required)' : ''}`
        this.noteTarget.placeholder = `Add comment${this.requiringNote ? ' (required)' : ''}`
        this.updateSubmitButtonStatus()
      })
    }
  }

  disconnect() {
    this.myDropzone = null
  }
}
