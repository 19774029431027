import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']

  _select(buttonTarget, toggle) {
    const radio = buttonTarget.querySelector('input[type=radio]')
    if (radio) {
      radio.checked = toggle
      radio.dispatchEvent(new CustomEvent('change'))
    } else {
      const inputs = buttonTarget.querySelectorAll('input[type=hidden]')
      inputs.forEach(input => {
        input.disabled = !toggle
      })
    }
  }

  select(buttonTarget) {
    this._select(buttonTarget, true)
    
    this.buttonTargets.forEach(button => {
      if (button === buttonTarget) {
        button.classList.add('selected')
      } else {
        button.classList.remove('selected')
        this.deselect(button)
      }
    })
  }

  deselect(buttonTarget) {
    this._select(buttonTarget, false)
  }

  connect() {
    let parent
    const parentSelector = this.element.dataset.partialFormParentSelector
    if (parentSelector) {
      parent = document.querySelector(parentSelector)
    }

    if (parent) {
      this.buttonTargets.forEach(button => {
        button.addEventListener('click', (event) => {
          event.preventDefault()
          event.stopPropagation()
          this.select(button)
          console.log("next-page@window")
          window.dispatchEvent(new CustomEvent("next-page"))
        })
      })

      parent.addEventListener('appear', () => {
        this.buttonTargets.forEach(button => {
          if (!button.classList.contains('selected')) {
            this.deselect(button)
          }
        })
      })
    }
  }
}