import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from '~/helpers/utils'

export default class extends Controller {

  static targets = ['unit', 'react', 'total']

  collection = []

  connect() {
    for (const unit of this.unitTargets) {
      const unitPrice = Number(unit.dataset.unitPrice)
      const quantity = unit.querySelector('[data-quantity]')
      quantity.addEventListener('change', () => this.calculate())
      this.collection.push([unitPrice, () => {
        return quantity.value
      }])
    }
    this.calculate()
  }

  calculate() {
    let total = 0
    for (const [unitPrice, quantity] of this.collection) {
      total += unitPrice * quantity()
    }
    console.log(`total price = ${total}`)
    this.element.dataset.totalPrice = total
    if(this.hasTotalTarget) {
      this.totalTargets.forEach(e => {
        const currency = e.getAttribute("data-currency")
        e.innerHTML = formatCurrency(total, currency)
      });
    }
    for (const el of this.reactTargets) {
      const falseClass = el.dataset.sumReactFalseClass ?? 'invisible'
      const visible = this._evalVisibility(el.dataset.sumReactFn, total)
      el.classList.toggle(falseClass, !visible)
    }
  }

  _evalVisibility(sumReactFn, total) {
    const m = /^([<>]=?)\s?(\d*(?:\.\d*)?)$/.exec(sumReactFn)
    if (!m) return false
    let visible = false
    const threshold = Number(m[2])
    if (m[1] == '>') {
      visible = total > threshold
    } else if (m[1] == '>=') {
      visible = total >= threshold
    } else if (m[1] == '<') {
      visible = total < threshold
    } else if (m[1] == '<=') {
      visible = total <= threshold
    }
    return visible
  }
}
