import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['header', 'placeholder']

  connect() {
    this.update()
    this.observer = new ResizeObserver(entries => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return
        }
        this.update()
      })
    })
    this.observer.observe(this.headerTarget)
  }

  disconnect() {
    if(this.observer) {
      this.observer.disconnect()
    }
  }

  update() {
    const height = this.headerTarget.offsetHeight
    this.placeholderTarget.style.height = height + 'px'
  }
}