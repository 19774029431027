import { Controller } from "@hotwired/stimulus"
import select2 from 'select2';
select2();
import $ from 'jquery'

export default class extends Controller {
  forwardEvents() {
    // forward select2 change event as native javascript event
    const select = $(this.select);

    select.on('select2:select', function (e) {
      let data = e.params.data;
      if (data.id) {
        let selected_option;
        // using this instead of jquery to handle scenarios where the option value has special characters
        for (let i = select[0].options.length; i-->0;) {
          if (select[0].options[i].value == data.id) {
            selected_option = $(select[0].options[i]);
          }
        }

        // add any custom data-attributes to the option itself
        Object.entries(data).forEach(([key, value]) => {
          if (key.indexOf("data") !== -1) {
            selected_option.attr(key, value);
          }
        });

        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
      }
    });

    select.on('select2:opening', function (e) {
      let event = new Event('opening', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }

  get select() {
    return $(this.element);
  }

  getData(attr) {
    // clear the data cache every time to get latest value
    this.select.removeData();

    return this.select.data(attr);
  }

  get userFilters() {
    let userFilters = this.getData('resourceFilters');
    if (userFilters == 'undefined') {
      userFilters = { id: 0 }
    }

    return userFilters
  }

  get prefixedFilters() {
    let userFilters = this.userFilters
    let filterPrefixes = this.getData('resourcePrefix')

    if (filterPrefixes) {
      let prefixedFilters = {}
      prefixedFilters[filterPrefixes] = userFilters
      userFilters = prefixedFilters
    }

    return userFilters
  }

  connect() {
    this.forwardEvents();

    let config = {
      placeholder: this.element.getAttribute("placeholder"),
      allowClear: this.element.getAttribute("placeholder") !== null,
      width: '100%'
    };
    if (this.getData('resourceUrl')) {
      config.ajax = this.ajaxConfig();
      config.createTag = (input) => { this.createTag(input) }
      if (this.getData('tags')) {
        config.language = {
          noResults: function () {
            return "No results found. Type '++' at the end of the name to create it. "
          }
        }
      }
    }

    this.select.select2(config);
  }

  mergeParams(filter1, filter2) {
    // merge other params (e.g. user filters)
    if (filter2) {
      filter1 = {
        ...filter1,
        ...filter2
      }
    }

    return filter1;
  }

  ajaxConfig() {
    let stimulus = this;

    return {
      url: stimulus.getData('resourceUrl'),
      data: function(params) {
        let userFilters = stimulus.prefixedFilters;

        let query = stimulus.mergeParams(
    { term: params.term, formatting: 'select2' },
    { filters: userFilters }
        );

        return query;
      },
      dataType: 'json'
    }
  }

  createTag(input) {
    const shorthand = '++'
    const total_length = input.term.length;
    const shorthand_position = input.term.indexOf(shorthand)

    if (shorthand_position + 2 !== total_length || total_length <= shorthand.length)  {
      // Must use secret shorthand to create new object
      return null;
    }

    const name = input.term.slice(0, -shorthand.length);
    let params = this.createTagParams(name);

    this.createTagAjaxCall(this.getData('resourceUrl'), params);
  }

  createTagParams(name) {
    let params = {
      formatting: 'select2'
    };
    const resourceName = this.getData('resourceName');
    const parameterName = this.getData('resourceParameter') || 'name';
    params[resourceName] = {};
    params[resourceName][parameterName] = name;

    params[resourceName] = this.mergeParams(params[resourceName], this.userFilters)
    console.log(params)

    return params;
  }

  createTagAjaxCall(url, params) {
    $.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      data: params,
      success: (response) => { this.createTagSuccess(response) },
      error: function(jqXHR, textStatus, errorThrown) {
        alert("Error, status = " + textStatus + ", " +
          "error thrown: " + errorThrown
        );
      }
    });
  }

  createTagSuccess(response) {
    const newOption = new Option(
      response.text,
      response.id,
      false,
      true
    )

    this.select.append(newOption).trigger('change').select2('close');

    this.select.trigger({
      type: 'select2:select',
      params: {
        data: response
      }
    });
  }

  disconnect() {
    this.select.select2('destroy');
  }
}
