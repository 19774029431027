import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  reset() {
    this.element.reset()  // reset form contents

    this.element.querySelectorAll("[type=submit],input[type=file]").forEach(e => {
      // reset data-disable-with
      // reset direct upload file inputs (active storage disables these on submit)
      Rails.enableElement(e)
      e.disabled = false
    })
  }
}
