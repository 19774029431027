import { Controller } from "@hotwired/stimulus"
import { Client } from "@twilio/conversations"
import { getFileSuffix } from '~/helpers/utils'

export default class extends Controller {
  static targets = ['message', 'messages', 'form', 'files', 'fileList', 'errorInfo', 'score', 'endInfo', 'messageTemplate', 'mediaTemplate', 'typingTemplate', 'previewTemplate']
  static values = {
    token: String,
    externalId: String,
    conversationId: String,
    agentname: String,
    endAt: String
  }

  disabled = false
  userIdentity = ''
  fileList = []

  async connect() {
    this.initConversation()
    this.mobileFocus()
  }

  async disconnect() {
    if (this.conversation) {
      await this.conversation.leave()
      this.conversation.removeAllListeners()
    }
  }

  mobileFocus() {
    // fix keyboard bug on IOS
    const isMobile = /ipad|iphone/.test(navigator.userAgent.toLocaleLowerCase()) && !window.MSStream;
    if (this.hasMessageTarget && isMobile) {
      this.messageTarget.addEventListener('focus', (e) => {
        setTimeout(() => {
          e.target.scrollIntoViewIfNeeded();
        }, 300)
      })
    }
  }

  initConversation() {
    if (this.endAtValue) {
      this.setEndTimeStamp(this.endAtValue)
      this.disabled = true
      return
    }
    this.client = new Client(this.tokenValue);
    this.client.on("initialized", () => this.initialized());
    this.client.on('connectionError', (error) => {
      this.showError(error.message)
      this.disabled = true
    })
    this.client.on('initFailed', ({ error }) => {
      this.showError(error.message)
      this.disabled = true
    });
  }

  async initialized() {
    try {
      this.conversation = await this.client.getConversationBySid(this.externalIdValue);
      this.userIdentity = this.conversation._configuration.userIdentity
      this.conversation.on('updated', ({ conversation, updateReasons }) => {
        if (updateReasons.includes('state') && conversation.state.current == 'closed') {
          // conversation closed
          this.removeItem(this.messagesTarget, '.typing-bubble')
          this.dispatch('endChat')
          this.disabled = true
        }
      })
      this.conversation.on('messageAdded', (message) => {
        if (message.body || message.attachedMedia?.length) {
          this.appendMessage(message)
          this.dispatch('resetTime')
        } else if (message.attributes?.activity) {
          switch(message.attributes.activity) {
            case 'typing:start':
              console.log('typing:start')
              this.generateTypingBubble()
              break;
            case 'typing:stop':
              console.log('typing:stop')
              this.removeItem(this.messagesTarget, '.typing-bubble')
              break;
          }
        }
      })
      this.conversation.on('disconnected', () => {
        this.showError('Socket error, trying to reconnect...')
        this.conversation.retryConnection();
      });
      this.listenRemoveLocalFile()
      this.messageBoxKeydown()
    } catch (error) {
      this.showError(error.body ? error.body.message : error.message)
    }
  }

  messageBoxKeydown() {
    this.messageTarget.addEventListener('keydown', event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage(event)
      }
    })
  }

  async sendMessage(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.conversation || this.disabled || (!this.messageTarget.value && !this.fileList.length)) return;
    let message = await this.conversation.prepareMessage().setBody(this.messageTarget.value)
    this.fileList.forEach((file) => {
      message = message.addMedia({
        contentType: file.type,
        filename: file.name,
        media: file
      })
    })
    message.buildAndSend().catch((error) => {
      console.dir(error)
      this.showError('Error. Fail to upload please try again')
    })
    this.resetMessage()
    this.messageTarget.focus()
  }

  appendMessage(message) {
    this.removeItem(this.messagesTarget, '.typing-bubble')
    this.dispatch('setTime', { detail: { date: message.dateCreated } })
    this.generateMessage(message)
    this.appendMedia(message.attachedMedia, message)
    this.scrollToEnd()
  }

  generateMessage(message) {
    const template = this.messageTemplateTarget.content.cloneNode(true).querySelector('.message-wrap')
    template.setAttribute('data-sid', message.sid)
    template.setAttribute('data-time', message.dateCreated)
    if (message.author != this.userIdentity) {
      template.querySelector('.assistant-name').innerHTML = this.agentnameValue
    } else {
      this.removeItem(template, '.assistant-name')
      template.querySelector('.message-item').classList.add('user')
      this.removeItem(template, '.like-btn')
    }
    if (message.body) {
      template.querySelector('.message-bubble').innerHTML = message.body
    } else {
      this.removeItem(template, '.message-bubble')
    }
    this.messagesTarget.appendChild(template)
  }

  appendMedia(mediaList, message) {
    const isUser = message.author == this.userIdentity
    mediaList.forEach(async (file) => {
      const url = await file.getContentTemporaryUrl()
      this.messagesTarget.querySelector(`.message-wrap[data-sid="${message.sid}"]`).appendChild(this.generateMedia(file, url, isUser))
      this.scrollToEnd()
    })
  }

  // Add Files
  uploadFile(e) {
    const files = e.target.files
    this.fileList.push(...files)
    this.previewImage(files)
  }
  dropFile({ detail: { files } }) {
    this.fileList.push(...files)
    this.previewImage(files)
  }
  previewImage(list) {
    list.forEach(file => {
      if (file.size > 20 * 1024 * 1024) {
        this.showError("Please upload files smaller than 20M")
        return
      }
      const reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const template = this.previewTemplateTarget.content.cloneNode(true)
        if (file.type.includes('image')) {
          const url = e.target.result
          template.querySelector('img').setAttribute('src', url)
          this.removeItem(template, '.file-preview')
        } else {
          const suffix = getFileSuffix(file.name);
          template.querySelector('.file-preview').innerHTML = suffix
          this.removeItem(template, 'img')
        }
        template.querySelector('.file-name').innerHTML = file.name
        this.fileListTarget.appendChild(template)
      };
    })
  }
  listenRemoveLocalFile() {
    this.fileListTarget.addEventListener('click', (e) => {
      if (e.target.classList.contains('remove-icon')) {
        const fileItem = e.target.parentNode
        const index = Array.from(fileItem.parentNode.children).indexOf(fileItem);
        this.fileList.splice(index, 1)
        fileItem.remove()
      }
    });
  }

  // Email Chat
  emailChat() {
    console.log('emailChat')
    if (this.disabled) return
  }

  generateMedia(file, url, isUser) {
    const template = this.mediaTemplateTarget.content.cloneNode(true)
    const item = template.querySelector('div')
    if (isUser) {
      item.classList.add('flex', 'justify-end')
    }
    item.querySelector('a').setAttribute('href', url)
    if (file.contentType?.includes('image')) {
      item.querySelector('img').setAttribute('src', url)
      this.removeItem(item, 'i')
    } else {
      this.removeItem(item, 'img')
    }
    item.querySelector('.filename').innerHTML = file.filename
    item.querySelector('.suffix').innerHTML = getFileSuffix(file.filename).toUpperCase()
    return item
  }

  generateTypingBubble() {
    this.messagesTarget.appendChild(this.typingTemplateTarget.content.cloneNode(true))
    this.scrollToEnd()
  }

  resetMessage() {
    this.dispatch("done", { target: this.formTarget })
    this.fileList = []
    this.fileListTarget.innerHTML = ''
  }
  scrollToEnd() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }
  showError(message) {
    this.errorInfoTarget.innerHTML = message
    this.errorInfoTarget.classList.add('active')
    setTimeout(() => {
      this.errorInfoTarget.classList.remove('active')
    }, 5000)
  }
  removeItem(wrap, item) {
    wrap.querySelectorAll(item)?.forEach(item => item.remove())
  }
}
