import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { errors: String }

  locateError() {
    const errors = JSON.parse(this.errorsValue)
    if (typeof errors !== 'object') return
    try {
      for (const key of Object.keys(errors)) {
        const el = this.element.querySelector(`[data-form-error-for=${key}]`)
        if (el) {
          this.renderError(key, errors[key], el)
        }
      }
    } catch (error) {
      console.log(errors, error);
    }
  }

  renderError(key, error, el) {
    this.element.classList.add(`errors-${key}`)
    el.classList.add('errors')
    el.innerHTML = ''
    const i = document.createElement('i')
    i.classList.add('info-reverse')
    el.appendChild(i)
    const span = document.createElement('span')
    span.innerHTML = error
    el.appendChild(span)
  }

  connect() {
    this.locateError()
  }
}