import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.querySelectorAll('[data-window-action]').forEach(clickable => {
      clickable.onclick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const data = { detail: clickable.dataset }
        for (const action of clickable.dataset.windowAction.split(' ')) {
          console.log(action, data)
          const customEvent = new CustomEvent(action, data)
          window.dispatchEvent(customEvent)
        }
      }
    })
  }
}