import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ['title', 'tab', 'pageindicator']
  static values = { tab: Number, progress: String, 'previous_class': String, previousClass: String }

  connect() {
    this.updateDisplay()
  }
  
  updateDisplay() {
    this.tabTargets.forEach((tab) => {
      const idx = Number(tab.dataset.tabIdx)
      tab.classList.toggle('current', this.tabValue === idx + 1)
      tab.classList.toggle('selected', this.tabValue === idx + 1)
    })
    if (this.hasPageindicatorTarget) {
      const dots = []
      if (this.progressValue) {
        const [current, total] = this.progressValue.split('/').map(Number)
        console.log([current, total])
        for (let i = 1; i <= total; i++) {
          if (current > i) {
            dots.push(`<div class="dot ${this.previousClassValue}"></div>`)
          } else if (current === i) {
            dots.push('<div class="dot on"></div>')
          } else {
            dots.push('<div class="dot"></div>')
          }
        }
      }
      this.pageindicatorTargets.forEach(target => {
        target.classList.toggle('pt-6', this.progressValue)
        target.innerHTML = dots.join('')
      })
    }
  }

  updateTitle(title) {
    if (title !== undefined) {
      this.titleTargets.forEach(target => {
        target.innerHTML = title.replace(/\n/g, '<br>')
      })
    }
  }

  updateTab(tab) {
    if (tab !== undefined) {
      this.tabValue = tab
    }
  }

  updateProgress(progress) {
    if (progress !== undefined) {
      this.progressValue = progress
    }
  }

  update(event) {
    if (event.detail) {
      const { title, tab, progress } = event.detail
      this.updateTitle(title)
      this.updateTab(tab)
      this.updateProgress(progress)
      this.updateDisplay()
    }
  }
}