import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['popup']

  static values = { content: String }

  copy(event) {
    event.preventDefault()
    this.copyTextToClipboard(this.contentValue)
  }

  showPopup() {
    this.popupTarget.classList.remove('hidden')
    this.popupTarget.classList.add('flex')
    setTimeout(() => {
      this.popupTarget.classList.add('hidden')
      this.popupTarget.classList.remove('flex')
    }, 500)
  }

  copyTextToClipboard(text) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        if (this.hasPopupTarget) {
          this.showPopup()
        }
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    } else {
      console.error('Async: Could not copy text: ', err)
    }
  }
}