import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['map', 'cell']

  static values = { gesture: Boolean }

  map
  markers = []
  locations
  zIndex = 10

  pinStyles() {
    return {
      icon: {
        path: "M15 0A15 15 0 0 0 0 15.2c0 3.4 1 6.5 3 9.2l11 15.1.2.1a1 1 0 0 0 1.7 0L27.2 24A15.3 15.3 0 0 0 15 0Zm0 23.5a8 8 0 0 1-8-8.3 8 8 0 1 1 16 0 8 8 0 0 1-8 8.3Z",
        fillColor: "#292929",
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new google.maps.Point(30 / 2, 40),
        labelOrigin: new google.maps.Point(66, 0)
      },
      label: {
        fontFamily: "'Inter', sans-serif",
        fontSize: '12px',
        fontWeight: '700',
        color: '#292929',
        className: 'map-location-name'
      }
    }
  }

  setLocations(event) {
    const json = JSON.parse(event.detail.json)
    this.locations = json
    this.buildLocations()
  }
  
  setLocation(event) {
    const json = JSON.parse(event.detail.json)
    this.locations = [json]
    this.buildLocations()
  }

  resetMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null)
    })
    this.markers.splice(0, this.markers.length)
  }

  buildLocations() {
    this.resetMarkers()
    this.zIndex = 10
    let hasCenter = false
    const style = this.pinStyles()
    this.locations.forEach((location, index) => {
      const coordinates = new google.maps.LatLng(location.coordinates[0], location.coordinates[1])
      if (!hasCenter) {
        this.map.setCenter(coordinates)
        hasCenter = true
      }
      const marker = new google.maps.Marker({
        map: this.map,
        position: coordinates,
        label: { ...style.label, text: location.name },
        icon: style.icon,
        zIndex: this.zIndex++
      })
      marker.addListener('click', () => {
        this.navigate(index)
      })
      this.markers.push(marker)
    })
    this.enableNavigationToNearestLocation()
    this.navigate(0)
  }

  navigate(index) {
    const location = this.locations[index]
    const coordinates = new google.maps.LatLng(location.coordinates[0], location.coordinates[1])
    this.cellTargets.forEach((cell, i) => {
      cell.classList.toggle('visible', i == index)
    })
    this.map.panTo(coordinates)
    this.markers[index].setZIndex(this.zIndex++)
  }

  enableNavigationToNearestLocation() {
    this.map.addListener('dragend', () => {
      let minDistance = Number.MAX_VALUE
      let minIndex = 0
      const center = this.map.getCenter()
      this.locations.forEach((location, index) => {
        const coordinates = new google.maps.LatLng(location.coordinates[0], location.coordinates[1])
        const distance = google.maps.geometry.spherical.computeDistanceBetween(coordinates, center)
        if (minDistance > distance) {
          minDistance = distance
          minIndex = index
        }
      })
      if (minDistance < 250) {
        this.navigate(minIndex)
      } else {
        this.cellTargets.forEach((cell) => {
          cell.classList.toggle('visible', false)
        })
      }
    })
  }

  async connect() {
    await deferred_map.promise
    const location_names = JSON.parse(this.element.dataset.locationNames)
    const location_ids = JSON.parse(this.element.dataset.locationIds)
    const location_coordinates = JSON.parse(this.element.dataset.locationCoordinates)
    this.locations = location_names.map((name, i) => {
      return {
        name,
        id: location_ids[i],
        coordinates: location_coordinates[i]
      }
    })
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 17,
      minZoom: 11,
      disableDefaultUI: true,
      fullscreenControl: false,
      gestureHandling: this.gestureValue ? 'cooperative' : 'none',
      keyboardShortcuts: false
    })
    this.buildLocations()
  }
}