export const formatCurrency = (fee, currency = 'USD', placeholder = '') => {
  return fee ? new Intl.NumberFormat("en-US", { style: "currency", currency }).format(fee) : placeholder
}

export const formatDate = (inputDate) => {
  const now = new Date();
  let date;
  // format date for safari
  if(typeof inputDate == 'string') {
    date = new Date(inputDate.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3'));
  } else {
    date = new Date(inputDate)
  }
  if (isSameDay(now, date)) {
    return formatTime(date);
  } else {
    return `${formatTime(date)}, ${formatDateOnly(date)}`;
  }
}

const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return `${hours % 12 || 12}:${minutes} ${ampm}`;
}

const formatDateOnly = (date) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
}

export const startCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getFileSuffix = (filename = '') => {
  return filename.split('.').pop()
}