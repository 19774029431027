import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['chat', 'frame']

  active = false

  connect() {
    // fix cross-origin frame issue
    window.addEventListener('message', (event) => {
      if (event.data.eventName == 'closeChat') {
        this.toggleScroll(false)
        this.chatTarget.classList.toggle('active', false)
        this.element.classList.toggle('active', false)
      }
      if(event.data.eventName == 'getWindowWidth') {
        const width = window.innerWidth
        event.source.postMessage({ type: 'windowWidth', width }, '*');
      }
    })
  }

  toggleScroll(disable) {
    if (disable) {
      document.body.style.overflowY = 'hidden'
      document.body.style.height = '100%'
      document.documentElement.style.overflowY = 'hidden'
    } else {
      document.body.style.removeProperty('overflow-y')
      document.body.style.removeProperty('height')
      document.documentElement.style.removeProperty('overflow-y')
    }
  }

  toggleChat() {
    this.active = !this.chatTarget.classList.contains('active')
    this.chatTarget.classList.toggle('active')
    const isMobile = document.documentElement.clientWidth <= 768
    if (this.active) {
      this.element.classList.toggle('active', true)
      if (isMobile) {
        this.toggleScroll(true)
      }
    } else {
      setTimeout(() => {
        this.element.classList.toggle('active', false)
        this.toggleScroll(false)
      }, 150)
    }
  }
}