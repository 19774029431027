import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.toggleMobile()
    window.visualViewport.addEventListener('resize', this.toggleMobile.bind(this))
  }

  toggleMobile() {
    window.parent.postMessage({eventName: 'getWindowWidth'}, '*')
    const _this = this
    window.addEventListener('message', function (event) {
      if (event.data.type === 'windowWidth') {
        const width = event.data.width;
        _this.element.classList.toggle('mobile', width <= 768)
      }
    });
  }

  close() {
    setTimeout(() => {
      window.parent.postMessage({ eventName: 'closeChat' }, '*')
    })
  }

}