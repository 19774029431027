import { Controller } from "@hotwired/stimulus"
import { countries } from "./dialcode_data"
import { countryCodeEmoji } from "country-code-emoji"
import $ from 'jquery'
export default class extends Controller {
  static targets = ['select', 'phoneNumber']

  countryCode

  disconnect() {
    $(this.selectTarget).select2('destroy');
  }

  connect() {
    const { countryCode, localNumber, country } = this.selectTarget.dataset
    console.log({ countryCode, localNumber, country })

    const dialCodeSelect = $(this.selectTarget)
    const phoneNumber = this.phoneNumberTarget
    dialCodeSelect.select2({
      templateSelection: (data) => countryCodeEmoji(data.id),
      data: countries.map(country => ({
        id: country.iso2,
        country,
        text: `${countryCodeEmoji(country.iso2)} ${country.name.replace(/\s*\(.*\)$/, '')} (+${country.dialCode})`,
      })),
      width: '100%'
    })
    .val(country.toLowerCase()).trigger('change')
    .on('select2:select', event => {
      const { data: { country } } = event.params
      phoneNumber.dataset.countryCode = country.dialCode
      this.countryCode = country.dialCode
    })
    phoneNumber.dataset.countryCode = countryCode
    this.countryCode = countryCode
    const input = phoneNumber.querySelector('input')
    input.value = localNumber
    this.connectPhoneNumberInput(input)
  }

  connectPhoneNumberInput(input) {
    const hidden = document.createElement('input')
    hidden.type = 'hidden'
    hidden.value = input.value
    hidden.name = input.name
    input.addEventListener('input', () => {
      hidden.value = `+${this.countryCode}${input.value.replace(/\D/g, '')}`
    })

    // same name hidden input being after this input will override the input value, so no need to disable it
    input.parentNode.appendChild(hidden)
  }
}
