import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'popup']

  connect() {
    window.addEventListener('click', (element) => {
      if(element.target != this.buttonTarget) {
        this.popupTarget.classList.add("hidden")
      }
    })
  }

  toggle() {
    this.popupTarget.classList.toggle("hidden")
  }
}
