import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container']

  dragenter(e) {
    e.preventDefault();
    this.toggleContainer(e, false)
  }

  dragleave(e) {
    this.toggleContainer(e, true)
  }

  dragover(e) {
    e.preventDefault()
  }

  drop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files || []
    if (files.length) {
      this.dispatch('dropFile', { detail: { files } })
    }
    this.toggleContainer(true)
  }

  toggleContainer(e, hide) {
    if (e.relatedTarget === null || !this.element.contains(e.relatedTarget)) {
      this.containerTarget.classList.toggle('hidden', hide)
    }
  }
}