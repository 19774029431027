import { Controller } from "@hotwired/stimulus"
import { formatDate } from '~/helpers/utils'

export default class extends Controller {
  static targets = ['messages', 'endInfo']

  static values = {
    endAt: String
  }

  lastActivityTime = Date.now()
  lastSentTimer
  twilioController

  connect() {
    this.historyTimestamp()
    if (this.endAtValue) {
      this.setEndTimeStamp(this.endAtValue)
    }
  }

  historyTimestamp() {
    let timeFlag = ''
    this.messagesTarget.querySelectorAll('.message-wrap')?.forEach((item, index) => {
      // timestamp
      const itemTime = item.getAttribute('data-time')
      if (index == 0 || new Date(itemTime).getTime() - new Date(timeFlag).getTime() > 5 * 60 * 1000) {
        this.messagesTarget.insertBefore(this.generateTime(itemTime, true), item)
      }
      timeFlag = itemTime
    })
    this.dispatch('scrollToEnd')
  }

  // render last sent time after 5 mins
  resetActivityTime() {
    this.lastActivityTime = Date.now();
    const lastTime = this.messagesTarget.querySelector('.last-time')
    if (lastTime) {
      lastTime.remove()
    }
    clearInterval(this.lastSentTimer)
    this.setActivityInterval()
  }
  setActivityInterval() {
    this.lastSentTimer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - this.lastActivityTime > 5 * 60 * 1000) {
        const time = formatDate(this.lastActivityTime)
        const item = document.createElement('div');
        item.className = 'time last-time'
        item.innerHTML = `Last sent at ${time}`
        this.messagesTarget.appendChild(item)
        this.dispatch('scrollToEnd')
        clearInterval(this.lastSentTimer)
      }
    }, 60000);
  }

  // render time after 5 mins
  setTime(newDate) {
    const lastMessage = this.messagesTarget.querySelector('.message-wrap:nth-last-of-type(1)')
    if (!lastMessage) {
      this.generateTime(newDate)
      return
    }
    const lastDate = new Date(lastMessage.getAttribute('data-time'))
    if (new Date(newDate).getTime() - lastDate.getTime() > 5 * 60 * 1000) {
      this.generateTime(newDate)
    } else return
  }
  generateTime(date, getDate) {
    const item = document.createElement('div');
    item.className = 'time'
    item.innerHTML = formatDate(new Date(date))
    if (getDate) {
      return item
    } else {
      this.messagesTarget.appendChild(item)
      this.dispatch('scrollToEnd')
    }
  }

  setEndTimeStamp(date) {
    this.endInfoTarget.querySelector('span').innerHTML = formatDate(date)
    this.endInfoTarget.classList.add('active')
  }

  setEndTime({ detail: { date } }) {
    this.setEndTimeStamp(date)
  }

  setMessageTime({ detail: { date } }) {
    this.setTime(date)
  }
}
