import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  currentCheckbox

  check(e) {
    const element = e.target
    if (element.checked) {
      const type = element.getAttribute('data-type')
      const openBtn = this.element.querySelector(`.check-key-${type}`)
      if (type && openBtn) {
        openBtn.dispatchEvent(new Event('click'))
        this.currentCheckbox = element
      }
    }
  }

  keep() {
    this.currentCheckbox.checked = false
    this.currentCheckbox.dispatchEvent(new Event('change'))
    this.reset()
  }

  reset() {
    this.currentCheckbox = null
  }

}
