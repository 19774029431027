import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['source', 'destination']

  connect() {
    this.toggle();
  }

  toggle() {
    let selectedOption = this.sourceTarget.options[this.sourceTarget.selectedIndex];
    let enabledElementClass = selectedOption.dataset.toggledElementClass;

    this.destinationTargets.forEach(element => {
      let input = element.querySelectorAll('select, input');

      if (element.className.indexOf(enabledElementClass) == -1) {
        element.style.display = 'none';
        input.disabled = true;
      } else {
        element.style.display = 'block';
        input.disabled = false;
      }
    })
  }
}