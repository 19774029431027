import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['popup']

  static values = { buttonText: String, buttonUrl: String, sheetStyle: String, padding: String, clone: Boolean, responsiveFull: Boolean }

  originPopupTargetParentNode

  originPopupTarget

  windowEventListener

  connect() {
    this.originPopupTarget = this.popupTarget
    this.popupTargetOriginParentNode = this.originPopupTarget.parentNode
    if (this.responsiveFullValue) {
      this.appendCloseBar()
    }
    this.originPopupTarget.classList.add('hidden')
    this.windowEventListener = () => {
      this.dismiss()
    }
    window.addEventListener('dismiss-popups', this.windowEventListener)
  }

  disconnect() {
    this.originPopupTarget.classList.add('hidden')
    if (this.cloneValue === false) {
      this.popupTargetOriginParentNode.appendChild(this.originPopupTarget)
    }
    if (this.background) {
      this.background.remove()
    }
    window.removeEventListener('dismiss-popups', this.windowEventListener)
  }

  appendCloseBar() {
    if (this.popupTarget.classList.contains('close-bar')) {
      return
    }
    this.popupTarget.classList.add('close-bar', 'w-auto', 'md:h-auto', 'h-full', 'mt-0', 'md:mx-6', 'md:mb-6')
    const first = this.popupTarget.firstChild
    const button = document.createElement('button')
    button.classList.add('md:hidden', 'flex', 'justify-center', 'w-full')
    button.addEventListener('click', (e) => {
      e.preventDefault()
      this.dismiss()
    })
    const bar = document.createElement('div')
    bar.classList.add('bg-gray-81', 'opacity-30', 'rounded-full', 'h-[5px]', 'w-[91px]', 'my-[11px]')
    button.appendChild(bar)
    this.popupTarget.insertBefore(button, first)
    const hrBefore = this.popupTarget.querySelector('h1 + *') || first
    if (hrBefore) {
      const hr = document.createElement('hr')
      hr.classList.add('md:hidden', 'flex')
      this.popupTarget.insertBefore(hr, hrBefore)
    }
  }

  dismiss() {
    if (this.background) {
      this.originPopupTarget.classList.add('hidden')
      if (this.cloneValue === false) {
        this.popupTargetOriginParentNode.appendChild(this.originPopupTarget)
      }
      this.background.remove()
    }
  }

  appendCloseButton(parent, onclick) {
    const a = document.createElement('a')
    a.classList.add('btn-primary', 'mt-6', 'close-button')
    a.innerHTML = this.buttonTextValue || 'Close'
    if (this.buttonUrlValue && this.buttonUrlValue !== '#') {
      a.href = this.buttonUrlValue
      a.target = '_blank'
    } else {
      a.href = '#'
      a.addEventListener('click', onclick)
    }
    if (this.responsiveFullValue) {
      a.classList.add('hidden')
    }
    parent.appendChild(a)
  }

  appendCloseX(parent, onclick) {
    const closeX = document.createElement('button')
    closeX.classList.add('p-2', 'self-end', 'close-icon', 'mb-4')
    if (this.responsiveFullValue) {
      closeX.classList.add('hidden', 'md:block', 'mt-6', 'mr-6')
    }
    closeX.addEventListener('click', onclick)
    parent.appendChild(closeX)
  }

  appendPopupTarget(parent) {
    if (this.cloneValue === false) {
      parent.appendChild(this.originPopupTarget)
      this.originPopupTarget.classList.remove('hidden')
    } else {
      const clone = this.popupTarget.cloneNode(true)
      clone.classList.remove('hidden')
      parent.appendChild(clone)
    }
  }

  buildPopupBackground() {
    const other_background = document.body.querySelector('.popup-background')
    if (other_background) {
      return other_background
    }
    const background = document.createElement('div')
    background.classList.add('popup-background', 'fixed', 'inset-0', 'z-50', 'flex')
    document.body.appendChild(background)
    const shade = document.createElement('div')
    shade.classList.add('opacity-40', 'bg-black', 'fixed', 'inset-0')
    background.appendChild(shade)
    return background
  }

  buildSheetContainer() {
    const sheetContainer = document.createElement('div')
    sheetContainer.classList.add('popup-sheet-container', 'flex', 'w-full', 'flex-col')
    
    if (this.paddingValue) {
      sheetContainer.classList.add(this.paddingValue.split(' '))
    } else {
      if (this.responsiveFullValue) {
        sheetContainer.classList.add('p-0')
      } else {
        sheetContainer.classList.add('md:pt-8', 'pt-6', 'md:px-0', 'px-6')
      }
    }
    sheetContainer.addEventListener('click', (event) => {
      event.stopPropagation()
    })
    return sheetContainer
  }

  buildSheet() {
    const sheet = document.createElement('div')
    sheet.classList.add('sheet', 'inset-auto', 'z-10')
    if (this.sheetStyleValue) {
      sheet.classList.add(...this.sheetStyleValue.split(' '))
    } else {
      sheet.classList.add('m-auto', 'p-6', 'centered', 'text-center', 'w-auto')
    }
    if (this.responsiveFullValue) {
      sheet.classList.add('md:m-auto', 'mt-20', 'mx-0', 'mb-0', 'md:flex-none', 'flex-1', 'w-full')
    }
    return sheet
  }

  popup(event) {
    event.preventDefault()
    event.stopPropagation()
    const background = this.buildPopupBackground()
    this.background = background
    const existingSheetContainers = background.querySelectorAll('.popup-sheet-container')
    existingSheetContainers.forEach(container => container.classList.add('hidden'))
    const closeEvent = (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.dismiss()
    }
    background.addEventListener('click', closeEvent)
    const sheetContainer = this.buildSheetContainer()
    background.appendChild(sheetContainer)
    const sheet = this.buildSheet()
    this.appendCloseX(sheet, closeEvent)
    this.appendPopupTarget(sheet)
    let closeButton = sheet.querySelector('button.close')
    if (!closeButton) {
      this.appendCloseButton(sheet, closeEvent)
    } else {
      closeButton.addEventListener('click', closeEvent)
    }
    sheetContainer.appendChild(sheet)
  }
}