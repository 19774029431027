import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['value', 'minus', 'plus', 'checkbox']

  static values = { min: Number, max: Number }

  quantity;
  disabled = false;

  connect() {
    this.quantity = this.element.querySelector('[data-quantity]')
    if(this.hasCheckboxTarget) {
      this.onSelect(this.checkboxTarget)
      this.checkboxTarget.addEventListener('change', e => this.onSelect(e.target))
    } else {
      this.updateDisplay(+this.quantity.dataset.quantity)
    }
  }

  updateDisplay(value) {
    this.valueTarget.innerHTML = value
    this.minusTarget.classList.toggle('opacity-10', value <= this.minValue)
    this.plusTarget.classList.toggle('opacity-10', value >= this.maxValue)
  }

  disableBtn() {
    this.minusTarget.classList.toggle('opacity-10', true)
    this.plusTarget.classList.toggle('opacity-10', true)
  }

  sync(value) {
    this.quantity.dispatchEvent(new CustomEvent('changeTo', { detail: { to: value } }))
  }

  adjustValue(event, increment) {
    event.preventDefault();
    const value = +this.quantity.dataset.quantity
    const canDecrease = increment === -1 && (value > this.minValue && this.maxValue > 0);
    const canIncrease = increment === 1 && (value < this.maxValue);

    if (!this.disabled && (canDecrease || canIncrease)) {
      this.updateDisplay(value + increment);
      this.sync(value + increment);
    }
  }

  minus(event) {
    this.adjustValue(event, -1);
  }
  plus(event) {
    this.adjustValue(event, 1);
  }

  onSelect(e) {
    const checked = e.checked
    this.disabled = !checked
    if(checked) {
      this.updateDisplay(+this.quantity.dataset.quantity)
    } else {
      this.disableBtn()
    }
  }
}