import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['id', 'name', 'distance_value', 'distance_unit']

  setLocation(event) {
    const { id, name, distance } = JSON.parse(event.detail.json)
    this.idTarget.value = id
    this.nameTarget.innerHTML = name
    this.distance_valueTarget.innerHTML = distance.value
    this.distance_unitTarget.innerHTML = distance.unit
  }
}