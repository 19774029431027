import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['default', 'success']
  static values = { duration: Number }

  async connect() {
    this.successTarget.classList.add('hidden')
    const form = this.defaultTarget.querySelector('form')
    form.onsubmit = (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.onsubmit(form)
    }
  }

  buildData(inputs) {
    const data = {}
    inputs.forEach(input => {
      const m = /^rest\[(.*?)\]$/.exec(input.name)
      if (m) {
        data[m[1]] = input.value
      }
    })
    return data
  }

  showSuccess(inputs) {
    this.defaultTarget.classList.add('hidden')
    this.successTarget.classList.remove('hidden')
    setTimeout(() => {
      this.defaultTarget.classList.remove('hidden')
      this.successTarget.classList.add('hidden')
      inputs.forEach(input => {
        input.value = null
      })
    }, this.durationValue * 1000)
  }

  async onsubmit(form) {
    const inputs = form.querySelectorAll('input:not([type=submit])')
    try {
      const response = await fetch(form.action, {
        method: form.method.toUpperCase(),
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(this.buildData(inputs))
      })
      const json = await response.json()
      if (!response.ok) {
        throw new Error(json.error)
      }
      this.showSuccess(inputs)
    } catch (error) {
      alert(error.message || 'An unexpected error occurred. Please try again. ')
    } finally {
      form.querySelector('[type=submit]').disabled = false
    }
  }
}