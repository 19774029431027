import Rails from "@rails/ujs"
import "@hotwired/turbo"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import "channels/consumer"
import 'jquery'
import select2 from 'select2';
select2();
import '@captaincodeman/transition'

Rails.start()
ActiveStorage.start()

import "~/controllers"
